@import 'variables';
@import 'components/components';
@import 'forms/forms';
@import 'utilities/utilities';
@import 'pages/pages';

* {
  box-sizing: border-box;
}

html,
body {
  padding: 0;
  margin: 0;
  font-family: $font-family;
  font-size: 1rem;
  height: 100%;
  background-color: $color-background;
}
