.login-container {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr;
  height: 100vh;
  width: 100vw;
  // background-image: url('../../img/loginImg.png');
  // background-repeat: no-repeat;
  // background-position: right -50vw bottom;
  // background-size: contain;
  .login-form {
    align-items: center;
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: center;
    padding: 1rem;
    width: 100%;

    .logo {
      width: 10rem;
    }
  }
  .bg-container {
    display: none;
  }
}

@media screen and (min-width: $breakpoint-sm) {
  .login-container {
    .login-form {
      margin: auto;
      padding: 1rem 0;
      width: 50%;
    }
  }
}

@media screen and (min-width: $breakpoint-md) {
  
}

@media screen and (min-width: $breakpoint-lg) {
  .login-container {
    grid-template-columns: 1fr 1fr;
    .login-form {
      width: 75%;
    }
    .bg-container {
      background-image: url('../../img/loginImg.png');
      background-position: center;
      background-repeat: no-repeat;
      background-size: cover;
      display: inline;
      height: 100%;
      width: 100%;
    }
  }
}

@media screen and (min-width: $breakpoint-xl) {
  
}

@media screen and (min-width: $breakpoint-xxl) {
  .login-container {
    .login-form {
      width: 50%;
    }
  }
}