// Select
//
// Replaces the browser default select with a custom one, mostly pulled from
// https://primer.github.io/.

.form-select {
  display: block;
  width: 100%;
  padding: $input-padding-y 2.25rem $input-padding-y $input-padding-x;
  font-size: 1rem;
  color: $color-text;
  background-image: $form-select-indicator;
  background-repeat: no-repeat;
  background-position: right $input-padding-x center;
  background-size: 16px 12px;
  border: 1px solid $border-color;
  border-radius: $border-radius;
  appearance: none;

  &:focus {
    border-color: $primary-light;
    outline: 0;
    box-shadow: 0 0 0 0.20rem rgba($primary-light, 0.25);
  }

  &[multiple],
  &[size]:not([size="1"]) {
    padding-right: $input-padding-x;
    background-image: none;
  }

  &:disabled {
    background-color: $color-disabled;
    border-color: $border-color;
  }

  &:-moz-focusring {
    color: transparent;
    text-shadow: 0 0 0 $color-text;
  }
}