.report-filters {
  display: grid;
  width: 100%;
  grid-template-columns: repeat(1, 1fr);
  gap: 0;

  button {
    align-self: end;
    margin-bottom: 1.4rem;
  }
}

@media screen and (min-width: $breakpoint-lg) {
  .report-filters {
    grid-template-columns: repeat(4, minmax(100px, 1fr));
    gap: 1rem;
  }
}
