@import 'mixins';

.app {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 2.25rem 1fr 2.25rem;
  grid-template-areas: 'header' 'main' 'footer';
  height: 100vh;
}

@media screen and (min-width: $breakpoint-md) {
  .app {
    grid-template-columns: 15rem 1fr;
    grid-template-areas: 'sidebar header' 'sidebar main' 'sidebar footer';
  }
}

@import 'header';
@import 'sidebar';
@import 'main';
@import 'footer';
@import 'crud';
