// Margin
.m-0 { margin: 0; }
.m-1 { margin: 0.25rem; }
.m-2 { margin: 0.5rem; }
.m-3 { margin: 1rem; }
.m-4 { margin: 1.5rem; }
.m-5 { margin: 3rem; }
.m-auto { margin: auto; }
// Margin x
.mx-0 { margin-right: 0; margin-left: 0; }
.mx-1 { margin-right: 0.25rem; margin-left: 0.25rem; }
.mx-2 { margin-right: 0.5rem; margin-left: 0.5rem; }
.mx-3 { margin-right: 1rem; margin-left: 1rem; }
.mx-4 { margin-right: 1.5rem; margin-left: 1.5rem; }
.mx-5 { margin-right: 3rem; margin-left: 3rem; }
.mx-auto { margin-right: auto; margin-left: auto; }
// Margin y
.my-0 { margin-top: 0; margin-bottom: 0; }
.my-1 { margin-top: 0.25rem; margin-bottom: 0.25rem; }
.my-2 { margin-top: 0.5rem; margin-bottom: 0.5rem; }
.my-3 { margin-top: 1rem; margin-bottom: 1rem; }
.my-4 { margin-top: 1.5rem; margin-bottom: 1.5rem; }
.my-5 { margin-top: 3rem; margin-bottom: 3rem; }
.my-auto { margin-top: auto; margin-bottom: auto; }
// Margin top
.mt-0 { margin-top: 0; }
.mt-1 { margin-top: 0.25rem; }
.mt-2 { margin-top: 0.5rem; }
.mt-3 { margin-top: 1rem; }
.mt-4 { margin-top: 1.5rem; }
.mt-5 { margin-top: 3rem; }
.mt-auto { margin-top: auto; }
// Margin right
.mr-0 { margin-right: 0; }
.mr-1 { margin-right: 0.25rem; }
.mr-2 { margin-right: 0.5rem; }
.mr-3 { margin-right: 1rem; }
.mr-4 { margin-right: 1.5rem; }
.mr-5 { margin-right: 3rem; }
.mr-auto { margin-right: auto; }
// Margin bottom
.mb-0 { margin-bottom: 0; }
.mb-1 { margin-bottom: 0.25rem; }
.mb-2 { margin-bottom: 0.5rem; }
.mb-3 { margin-bottom: 1rem; }
.mb-4 { margin-bottom: 1.5rem; }
.mb-5 { margin-bottom: 3rem; }
.mb-auto { margin-bottom: auto; }
// Margin left
.ml-0 { margin-left: 0; }
.ml-1 { margin-left: 0.25rem; }
.ml-2 { margin-left: 0.5rem; }
.ml-3 { margin-left: 1rem; }
.ml-4 { margin-left: 1.5rem; }
.ml-5 { margin-left: 3rem; }
.ml-auto { margin-left: auto; }

// Padding
.p-0 { padding: 0; }
.p-1 { padding: 0.25rem; }
.p-2 { padding: 0.5rem; }
.p-3 { padding: 1rem; }
.p-4 { padding: 1.5rem; }
.p-5 { padding: 3rem; }
// Padding x
.px-0 { padding-right: 0; padding-left: 0; }
.px-1 { padding-right: 0.25rem; padding-left: 0.25rem; }
.px-2 { padding-right: 0.5rem; padding-left: 0.5rem; }
.px-3 { padding-right: 1rem; padding-left: 1rem; }
.px-4 { padding-right: 1.5rem; padding-left: 1.5rem; }
.px-5 { padding-right: 3rem; padding-left: 3rem; }
// Padding y
.py-0 { padding-top: 0; padding-bottom: 0; }
.py-1 { padding-top: 0.25rem; padding-bottom: 0.25rem; }
.py-2 { padding-top: 0.5rem; padding-bottom: 0.5rem; }
.py-3 { padding-top: 1rem; padding-bottom: 1rem; }
.py-4 { padding-top: 1.5rem; padding-bottom: 1.5rem; }
.py-5 { padding-top: 3rem; padding-bottom: 3rem; }
// Padding top
.pt-0 { padding-top: 0; }
.pt-1 { padding-top: 0.25rem; }
.pt-2 { padding-top: 0.5rem; }
.pt-3 { padding-top: 1rem; }
.pt-4 { padding-top: 1.5rem; }
.pt-5 { padding-top: 3rem; }
// Padding right
.pr-0 { padding-right: 0; }
.pr-1 { padding-right: 0.25rem; }
.pr-2 { padding-right: 0.5rem; }
.pr-3 { padding-right: 1rem; }
.pr-4 { padding-right: 1.5rem; }
.pr-5 { padding-right: 3rem; }
// Padding bottom
.pb-0 { padding-bottom: 0; }
.pb-1 { padding-bottom: 0.25rem; }
.pb-2 { padding-bottom: 0.5rem; }
.pb-3 { padding-bottom: 1rem; }
.pb-4 { padding-bottom: 1.5rem; }
.pb-5 { padding-bottom: 3rem; }
// Padding left
.pl-0 { padding-left: 0; }
.pl-1 { padding-left: 0.25rem; }
.pl-2 { padding-left: 0.5rem; }
.pl-3 { padding-left: 1rem; }
.pl-4 { padding-left: 1.5rem; }
.pl-5 { padding-left: 3rem; }

@each $name, $breakpoint in $media-queries {
  @media screen and (min-width: $breakpoint) {
    // Margin
    .m-#{$name}-0 { margin: 0; }
    .m-#{$name}-1 { margin: 0.25rem; }
    .m-#{$name}-2 { margin: 0.5rem; }
    .m-#{$name}-3 { margin: 1rem; }
    .m-#{$name}-4 { margin: 1.5rem; }
    .m-#{$name}-5 { margin: 3rem; }
    .m-#{$name}-auto { margin: auto; }
    // Margin x
    .mx-#{$name}-0 { margin-right: 0; margin-left: 0; }
    .mx-#{$name}-1 { margin-right: 0.25rem; margin-left: 0.25rem; }
    .mx-#{$name}-2 { margin-right: 0.5rem; margin-left: 0.5rem; }
    .mx-#{$name}-3 { margin-right: 1rem; margin-left: 1rem; }
    .mx-#{$name}-4 { margin-right: 1.5rem; margin-left: 1.5rem; }
    .mx-#{$name}-5 { margin-right: 3rem; margin-left: 3rem; }
    .mx-#{$name}-auto { margin-right: auto; margin-left: auto; }
    // Margin y
    .my-#{$name}-0 { margin-top: 0; margin-bottom: 0; }
    .my-#{$name}-1 { margin-top: 0.25rem; margin-bottom: 0.25rem; }
    .my-#{$name}-2 { margin-top: 0.5rem; margin-bottom: 0.5rem; }
    .my-#{$name}-3 { margin-top: 1rem; margin-bottom: 1rem; }
    .my-#{$name}-4 { margin-top: 1.5rem; margin-bottom: 1.5rem; }
    .my-#{$name}-5 { margin-top: 3rem; margin-bottom: 3rem; }
    .my-#{$name}-auto { margin-top: auto; margin-bottom: auto; }
    // Margin top
    .mt-#{$name}-0 { margin-top: 0; }
    .mt-#{$name}-1 { margin-top: 0.25rem; }
    .mt-#{$name}-2 { margin-top: 0.5rem; }
    .mt-#{$name}-3 { margin-top: 1rem; }
    .mt-#{$name}-4 { margin-top: 1.5rem; }
    .mt-#{$name}-5 { margin-top: 3rem; }
    .mt-#{$name}-auto { margin-top: auto; }
    // Margin right
    .mr-#{$name}-0 { margin-right: 0; }
    .mr-#{$name}-1 { margin-right: 0.25rem; }
    .mr-#{$name}-2 { margin-right: 0.5rem; }
    .mr-#{$name}-3 { margin-right: 1rem; }
    .mr-#{$name}-4 { margin-right: 1.5rem; }
    .mr-#{$name}-5 { margin-right: 3rem; }
    .mr-#{$name}-auto { margin-right: auto; }
    // Margin bottom
    .mb-#{$name}-0 { margin-bottom: 0; }
    .mb-#{$name}-1 { margin-bottom: 0.25rem; }
    .mb-#{$name}-2 { margin-bottom: 0.5rem; }
    .mb-#{$name}-3 { margin-bottom: 1rem; }
    .mb-#{$name}-4 { margin-bottom: 1.5rem; }
    .mb-#{$name}-5 { margin-bottom: 3rem; }
    .mb-#{$name}-auto { margin-bottom: auto; }
    // Margin left
    .ml-#{$name}-0 { margin-left: 0; }
    .ml-#{$name}-1 { margin-left: 0.25rem; }
    .ml-#{$name}-2 { margin-left: 0.5rem; }
    .ml-#{$name}-3 { margin-left: 1rem; }
    .ml-#{$name}-4 { margin-left: 1.5rem; }
    .ml-#{$name}-5 { margin-left: 3rem; }
    .ml-#{$name}-auto { margin-left: auto; }

    // Padding
    .p-#{$name}-0 { padding: 0; }
    .p-#{$name}-1 { padding: 0.25rem; }
    .p-#{$name}-2 { padding: 0.5rem; }
    .p-#{$name}-3 { padding: 1rem; }
    .p-#{$name}-4 { padding: 1.5rem; }
    .p-#{$name}-5 { padding: 3rem; }
    // Padding x
    .px-#{$name}-0 { padding-right: 0; padding-left: 0; }
    .px-#{$name}-1 { padding-right: 0.25rem; padding-left: 0.25rem; }
    .px-#{$name}-2 { padding-right: 0.5rem; padding-left: 0.5rem; }
    .px-#{$name}-3 { padding-right: 1rem; padding-left: 1rem; }
    .px-#{$name}-4 { padding-right: 1.5rem; padding-left: 1.5rem; }
    .px-#{$name}-5 { padding-right: 3rem; padding-left: 3rem; }
    // Padding y
    .py-#{$name}-0 { padding-top: 0; padding-bottom: 0; }
    .py-#{$name}-1 { padding-top: 0.25rem; padding-bottom: 0.25rem; }
    .py-#{$name}-2 { padding-top: 0.5rem; padding-bottom: 0.5rem; }
    .py-#{$name}-3 { padding-top: 1rem; padding-bottom: 1rem; }
    .py-#{$name}-4 { padding-top: 1.5rem; padding-bottom: 1.5rem; }
    .py-#{$name}-5 { padding-top: 3rem; padding-bottom: 3rem; }
    // Padding top
    .pt-#{$name}-0 { padding-top: 0; }
    .pt-#{$name}-1 { padding-top: 0.25rem; }
    .pt-#{$name}-2 { padding-top: 0.5rem; }
    .pt-#{$name}-3 { padding-top: 1rem; }
    .pt-#{$name}-4 { padding-top: 1.5rem; }
    .pt-#{$name}-5 { padding-top: 3rem; }
    // Padding right
    .pr-#{$name}-0 { padding-right: 0; }
    .pr-#{$name}-1 { padding-right: 0.25rem; }
    .pr-#{$name}-2 { padding-right: 0.5rem; }
    .pr-#{$name}-3 { padding-right: 1rem; }
    .pr-#{$name}-4 { padding-right: 1.5rem; }
    .pr-#{$name}-5 { padding-right: 3rem; }
    // Padding bottom
    .pb-#{$name}-0 { padding-bottom: 0; }
    .pb-#{$name}-1 { padding-bottom: 0.25rem; }
    .pb-#{$name}-2 { padding-bottom: 0.5rem; }
    .pb-#{$name}-3 { padding-bottom: 1rem; }
    .pb-#{$name}-4 { padding-bottom: 1.5rem; }
    .pb-#{$name}-5 { padding-bottom: 3rem; }
    // Padding left
    .pl-#{$name}-0 { padding-left: 0; }
    .pl-#{$name}-1 { padding-left: 0.25rem; }
    .pl-#{$name}-2 { padding-left: 0.5rem; }
    .pl-#{$name}-3 { padding-left: 1rem; }
    .pl-#{$name}-4 { padding-left: 1.5rem; }
    .pl-#{$name}-5 { padding-left: 3rem; }
  }
}
