.position-relative {
  position: relative;
}
.position-absolute {
  position: absolute;
}
.position-fixed {
  position: fixed;
}

.float-right {
  float: right;
}
.z-0 {
  z-index: 0;
}
.z-10 {
  z-index: 10;
}
.z-20 {
  z-index: 20;
}
.z-30 {
  z-index: 30;
}
.z-40 {
  z-index: 40;
}
.z-50 {
  z-index: 50;
}

@each $name, $breakpoint in $media-queries {
  @media screen and (min-width: $breakpoint) {
    .position-#{$name}-relative {
      position: relative;
    }
    .position-#{$name}-absolute {
      position: absolute;
    }
    .position-#{$name}-fixed {
      position: fixed;
    }
    .float-#{$name}-right {
      float: right;
    }
    .z-#{$name}-0 {
      z-index: 0;
    }
    .z-#{$name}-10 {
      z-index: 10;
    }
    .z-#{$name}-20 {
      z-index: 20;
    }
    .z-#{$name}-30 {
      z-index: 30;
    }
    .z-#{$name}-40 {
      z-index: 40;
    }
    .z-#{$name}-50 {
      z-index: 50;
    }
  }
}
