.table-employees {
  height: 25rem;
  overflow-y: auto;
}
.wrapper-buttons-send {
  display: flex !important;
}

@media screen and (min-width: $breakpoint-sm) {
  .wrapper-buttons-send {
    display: none !important;
  }
}

// @media screen and (min-width: $breakpoint-md) {
// }

// @media screen and (min-width: $breakpoint-lg) {
// }

// @media screen and (min-width: $breakpoint-xl) {
// }

// @media screen and (min-width: $breakpoint-xxl) {
// }
