.form-view {
  display: grid;
  grid-template-columns: 1fr;
}

@media screen and (min-width: $breakpoint-lg) {
  .form-view {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
  }
}
