// Flex direction
.flex-row { flex-direction: row; }
.flex-column { flex-direction: column; }
.flex-row-reverse { flex-direction: row-reverse; }
.flex-column-reverse { flex-direction: column-reverse; }

// Justify content
.justify-content-start { justify-content: flex-start; }
.justify-content-end { justify-content: flex-end; }
.justify-content-center { justify-content: center; }
.justify-content-between { justify-content: space-between; }
.justify-content-around { justify-content: space-around; }
.justify-content-evenly { justify-content: space-evenly; }

// Align items
.align-items-start { align-items: flex-start; }
.align-items-end { align-items: flex-end; }
.align-items-center { align-items: center; }
.align-items-baseline { align-items: baseline; }
.align-items-stretch { align-items: stretch; }

// Align self
.align-self-auto { align-self: auto; }
.align-self-start { align-self: flex-start; }
.align-self-end { align-self: flex-end; }
.align-self-center { align-self: center; }
.align-self-baseline { align-self: baseline; }
.align-self-stretch { align-self: stretch; }

// Flex size
.flex-1 { flex: 1; }
.flex-2 { flex: 2; }
.flex-3 { flex: 3; }
.flex-4 { flex: 4; }
.flex-5 { flex: 5; }

.flex-center { justify-content: center; align-items: center; }

@each $name, $breakpoint in $media-queries {
  @media screen and (min-width: $breakpoint) {
    // Flex direction
    .flex-#{$name}-row { flex-direction: row; }
    .flex-#{$name}-column { flex-direction: column; }
    .flex-#{$name}-row-reverse { flex-direction: row-reverse; }
    .flex-#{$name}-column-reverse { flex-direction: column-reverse; }

    // Justify content
    .justify-content-#{$name}-start { justify-content: flex-start; }
    .justify-content-#{$name}-end { justify-content: flex-end; }
    .justify-content-#{$name}-center { justify-content: center; }
    .justify-content-#{$name}-between { justify-content: space-between; }
    .justify-content-#{$name}-around { justify-content: space-around; }
    .justify-content-#{$name}-evenly { justify-content: space-evenly; }

    // Align items
    .align-items-#{$name}-start { align-items: flex-start; }
    .align-items-#{$name}-end { align-items: flex-end; }
    .align-items-#{$name}-center { align-items: center; }
    .align-items-#{$name}-baseline { align-items: baseline; }
    .align-items-#{$name}-stretch { align-items: stretch; }

    // Align self
    .align-self-#{$name}-auto { align-self: auto; }
    .align-self-#{$name}-start { align-self: flex-start; }
    .align-self-#{$name}-end { align-self: flex-end; }
    .align-self-#{$name}-center { align-self: center; }
    .align-self-#{$name}-baseline { align-self: baseline; }
    .align-self-#{$name}-stretch { align-self: stretch; }

    // Flex size
    .flex-#{$name}-1 { flex: 1; }
    .flex-#{$name}-2 { flex: 2; }
    .flex-#{$name}-3 { flex: 3; }
    .flex-#{$name}-4 { flex: 4; }
    .flex-#{$name}-5 { flex: 5; }

    .flex-#{$name}-center { justify-content: center; align-items: center; }
  }
}
