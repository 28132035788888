thead,
tbody,
tfoot,
tr,
td,
th {
  border-color: inherit;
  border-style: solid;
  border-width: 0;
}

.table {
  width: 100%;
  margin-bottom: $spacer;
  color: $color-text;
  vertical-align: middle;
  border-color: $border-color;
  text-align: left;
  border-spacing: 0;

  > :not(caption) > * > * {
    padding: 0.5rem 0.5rem;
    background-color: transparent;
    border-bottom-width: 1px;
    box-shadow: inset 0 0 0 9999px transparent;
  }

  > tbody {
    vertical-align: inherit;
    color: $color-text-light;
  }

  > thead {
    vertical-align: bottom;
  }

  // Color de borde entre el thead, tbody y tfoot
  > :not(:last-child) > :last-child > * {
    border-bottom-color: $border-color;
    border-bottom-width: 2px;
  }

  .sort {
    width: 1rem;
    color: $primary;
  }
}

.table-striped {
  > tbody > tr:nth-of-type(odd) {
    background-color: $color-background;
    color: $color-text-light;
  }
}

.table-responsive {
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
}

.add-search {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 1rem 0;
  .add {
    display: flex;
    margin-right: 0.5rem;
    .mobile,
    .desktop {
      margin-right: 0.5rem;
    }
    .desktop {
      display: none;
    }
  }
  .search {
    flex: 1;
  }

  @media screen and (min-width: $breakpoint-lg) {
    .add {
      flex: 1;
      .mobile {
        display: none;
      }
      .desktop {
        width: 4rem;
        display: block;
      }
    }
    .search {
      flex: 1;
    }
  }
}
