.dropzone {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 1.5rem;
  border-width: 2px;
  border-radius: 2px;
  border-color: $gray-light;
  border-style: dashed;
  background-color: $light;
  color: $gray-dark;
  outline: none;
  transition: border 0.24s ease-in-out;
  p {
    margin: 0;
    text-align: center;
  }
}

.dropzone:focus {
  border-color: $primary-light;
}
