// For IE11 compatibility
[role='main'] {
  grid-area: main;
  padding: 1rem;
  max-width: 100vw;
  max-height: calc(100vh - 4.5rem);
  overflow-y: auto;

  @media screen and (min-width: $breakpoint-md) {
    padding: 1rem 2rem;
  }
}
