$primary: #398afe;
$primary-light: #55b3ff;
$primary-dark: #1557b3;
$secondary: #6c757d;
$secondary-light: #79838b;
$secondary-dark: #5c636a;
$success: #198754;
$success-dark: #157347;
$warning: #ffc107;
$warning-dark: #ffca2c;
$danger: #dc3545;
$danger-dark: #bb2d3b;
$info: #0dcaf0;
$info-dark: #31d2f2;
$dark: #212529;
$dark-dark: #1c1f23;
$light: #f8f9fa;
$light-dark: #f9fafb;
$white: #fff;
$gray-light: #ebecec;
$gray: #aaa;
$gray-dark: #777;
$color-text: #333333;
$color-text-light: #555;
$color-background: #f6f8fc;
$color-disabled: #e9ecef;

$border-radius: 0.25rem;
$border-color: $gray-light;
$border-color-dark: $gray;

$spacer: 1rem;

$font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen,
  Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;

$input-padding-x: 0.75rem;
$input-padding-y: 0.375rem;
$form-select-indicator-color: $gray-dark !default;
$form-select-indicator: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23343a40' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 5l6 6 6-6'/%3e%3c/svg%3e") !default;

$menu-width-open: 200px;
$menu-width-collapsed: 64px;

$breakpoint-xs: 0;
$breakpoint-sm: 576px;
$breakpoint-md: 768px;
$breakpoint-lg: 960px;
$breakpoint-xl: 1200px;
$breakpoint-xxl: 1400px;
$media-queries: (
  'sm': $breakpoint-sm,
  'md': $breakpoint-md,
  'lg': $breakpoint-lg,
  'xl': $breakpoint-xl,
  'xxl': $breakpoint-xxl,
);

$theme-colors: (
  'primary' $primary $primary-dark $white,
  'secondary' $secondary $secondary-dark $white,
  'success' $success $success-dark $white,
  'info' $info $info-dark $color-text,
  'warning' $warning $warning-dark $color-text,
  'danger' $danger $danger-dark $white,
  'light' $light $light-dark $color-text,
  'dark' $dark $dark-dark $white
) !default;
