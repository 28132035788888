.header {
  grid-area: header;
  .wrapper-menu-icon {
    position: fixed;
    display: flex;
    top: 5px;
    left: 10px;
    align-items: center;
    justify-content: center;
    z-index: 1;
    cursor: pointer;
    padding: 5px;
    .menu-icon {
      color: black;
      width: 1.5rem;
      height: 1.5rem;
    }
  }
}
