.sidebar {
  background: linear-gradient(70deg, $primary, $primary-light);
  border-radius: 0 1rem 1rem 0;
  color: $white;
  display: flex;
  flex-direction: column;
  grid-area: sidebar;
  overflow-y: auto;
  padding: 1rem 0;
  position: fixed;
  transition: all 0.6s ease-in-out;
  transform: translateX(-15rem);
  width: 15rem;
  z-index: 201;
  height: 100vh;

  &.open {
    transform: translateX(0);
  }

  &-header {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;

    h4,
    small {
      text-align: center;
    }

    .sidebar-wrapper-close-icon {
      position: absolute;
      top: -1rem;
      right: 0rem;
      padding: 0.5rem;
      width: 1.5rem;
      height: 1.5rem;
      cursor: pointer;
      box-sizing: content-box;
      .sidebar-close-icon {
        color: $color-background;
      }
    }

    .logo {
      width: 40%;
      height: auto;
      margin-bottom: 1rem;
    }
  }

  &-menu {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    flex: 1;
    padding-left: 1rem;
    .menu-items {
      list-style: none;
      padding: 0;
      margin: 0;
      width: 100%;
    }
  }
  .menu-item {
    margin: 0.312rem 0;
    .menu-link {
      display: flex;
      align-items: center;
      padding: 0.4em 1em;
      color: #fefefe;
      font-size: 0.9rem;
      line-height: 1.4em;
      font-weight: 300;
      text-decoration: none;
      cursor: pointer;
      .menu-icon {
        width: 1.2rem;
        height: auto;
        margin: 0 1rem 0 0.5rem;
      }
      &:hover {
        transition: all 0.2s ease-in-out;
        transform: translateY(-0.1rem);
        text-shadow: 0px 2px 2px rgba(0, 0, 0, 0.1);
      }
    }
    &-selected {
      background-color: $color-background;
      border-radius: 1rem 0 0 1rem;
      position: relative;
      .menu-link {
        cursor: default;
        color: $primary;
        &:hover {
          transition: none;
          transform: none;
          text-shadow: none;
        }
      }
      &:before,
      &:after {
        content: '';
        position: absolute;
        height: 1rem;
        width: 1rem;
        bottom: 0;
      }
      &:before {
        top: -1rem;
        right: 0;
        border-radius: 0 0 1rem 0;
        -moz-border-radius: 0 0 1rem 0;
        -webkit-border-radius: 0 0 1rem 0;
        -webkit-box-shadow: 0.313rem 0.313rem 0 0.313rem $color-background;
        box-shadow: 0.313rem 0.313rem 0 0.313rem $color-background;
      }
      &:after {
        bottom: -1rem;
        right: 0;
        border-radius: 0 1rem 0 0;
        -moz-border-radius: 0 1rem 0 0;
        -webkit-border-radius: 0 1rem 0 0;
        -webkit-box-shadow: 0.313rem -0.313rem 0 0.313rem $color-background;
        box-shadow: 0.313rem -0.313rem 0 0.313rem $color-background;
      }
    }
  }

  &-footer {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    position: relative;

    small {
      display: flex;
      align-items: center;
      color: $light-dark;
      margin: auto;
      .heart {
        width: 1.2rem;
        height: auto;
        color: $danger;
      }
    }

    .menu-item {
      margin-left: 1rem;
      margin-bottom: 2rem;
    }
  }
}

// .menu-item-selected::before {
//   content: '';
// }

@media screen and (min-width: $breakpoint-md) {
  .sidebar {
    position: relative;
    transform: translateX(0);
    &-header {
      .sidebar-wrapper-close-icon {
        display: none;
      }
    }
  }
}
