.loader-wrapper {
  background: rgba($primary-light, 0.25);
  position: fixed;
  z-index: 999;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  img {
    width: 8rem;
    animation: s1 2s infinite;
  }

  h3 {
    color: $primary-dark;
  }
}

@keyframes s1 {
  50% {
    transform: rotate(0.5turn);
  }
  100% {
    transform: rotate(1turn);
  }
}

@keyframes ellipsis {
  to {
    width: 1.25em;
  }
}

@-webkit-keyframes ellipsis {
  to {
    width: 1.25em;
  }
}
