.d-none { display: none }
.d-inline { display: inline }
.d-block { display: block }
.d-grid { display: grid }
.d-flex { display: flex }

@each $name, $breakpoint in $media-queries {
  @media screen and (min-width: $breakpoint) {
    .d-#{$name}-none { display: none }
    .d-#{$name}-inline { display: inline }
    .d-#{$name}-block { display: block }
    .d-#{$name}-grid { display: grid }
    .d-#{$name}-flex { display: flex }
  }
}
